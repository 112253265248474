// Navigation styles

// NAV BAR
.navbar {
	@include transition(0.5s);

	&.open, &.change {
		box-shadow: 0 0 12px rgba(0,0,0,0.15);
	}
}
.navbar-brand {

	img {
		height: 38px;
	}
}
.navbar-toggler {
	border: none;
	padding: 6px;

	.navbar-toggler-bar {
		background-color: $navy;
		border-radius: 2px;
		width: 36px; height: 4px;

		+ .navbar-toggler-bar {
			margin-top: 6px;
		}
	}
}
#navbar {
	padding-right: 8px;
	text-align: right;

	ul {
		margin-top: 16px;
		margin-bottom: 16px;

		li {

			a {
				letter-spacing: 0.15em;
				text-transform: uppercase;
			}
			+ li {
				margin-top: 4px;
			}
		}
	}
}