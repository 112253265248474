// Site typography

h1, h2, h3 {
	font-family: $ff-serif;
}
h3 {
	color: $green;
	font-size: 20px;
}
p {
	line-height: 1.8;
}