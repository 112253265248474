// Global styles & resets

* {
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
body {
	font-family: $font-family-sans-serif;
	padding-top: 56px;
}

// Utility classes
.align-v {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

// Structure
.main-content {
	padding-top: 64px;
	padding-bottom: 64px;
	padding-left: 25px;
	padding-right: 25px;

	img {
		max-width: 100%;
	}
}

// Buttons
.btn {
	border-radius: 2px;
	font-weight: $bold;
	letter-spacing: 0.15em;
	padding: 8px 24px;
	text-transform: uppercase;

	&.btn-primary {
		background-color: $navy;
		border-color: $navy;
	}
}