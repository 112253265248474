// Responsive styles

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575px) {

}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {

	// Footer
	footer svg {
		margin-left: 0;
		margin-top: 40px;
		max-width: 100%;
	}
	#footer-nav {
		border-right: 2px solid $white;
		margin: 0;
		padding-right: 20px;
		text-align: right;
	}
	#footer-address {
		margin-top: 24px;
		text-align: left;
	}

	// Home
	#home-slider {
		height: 440px;

		.carousel-item .container > .row {
			height: 100%;
		}
		.slide-img, .slide-text {
			margin: 0;
			position: relative;
			top: 50%;
			transform: translateY(-50%);
		}
		.slide-img {
			height: auto;
		}
	}
	#home-services .home-service {
		margin-top: 0 !important;
		margin-bottom: 48px;
	}

	// Subpage
	.service-row {

		.service-row-icon {
			margin-top: 40px;
		}
		p {
			border-left: 2px solid $lightBlue;
			padding: 24px;
			text-align: left;
		}
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	// Global


	// Nav
	#navbar {
		padding-right: 0;
		text-align: left;

		ul {
			margin-top: 0;
			margin-bottom: 0;

			li {

				a {
					font-size: 16px;
				}
				+ li {
					margin-top: 0;
					margin-left: 16px;
				}
			}
		}
	}


	// Footer


	// Home
	#home-quote h2 {
		padding: 0 24px;

		&:first-child {
			margin-top: 12px;
		}
	}


	// Subpage
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {

	// Global
	body {
		padding-top: 56px;
	}
	.main-content {
		padding-top: 80px;
		padding-bottom: 80px;
	}

	// Nav
	#navbar {

		ul li {

			a {

			}
			+ li {
				margin-left: 16px;
			}
		}
	}

	// Footer


	// Home
	#home-slider {
		height: 560px;

		.slide-text {
			margin-top: 48px;
			padding-right: 24px;
		}
	}
	#home-quote h2:first-child {
		margin-top: 56px;
	}

	// Subpage
	#subpage-head {
		height: 480px;
	}
}