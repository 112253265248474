// Subpage styles

// HEADER
#subpage-head {
	color: $white;
	height: 400px;

	h1 {
		font-family: $ff-sans;
		letter-spacing: 0.15em;
		text-transform: uppercase;
	}
}

// BODY CONTENT
.service-row {
	margin-top: 64px;
	text-align: center;

	.service-row-icon {
		background-color: $navy;
		border-radius: 50%;
		display: block;
		margin: 0 auto 16px;
		position: relative;
		width: 64px; height: 64px;

		svg {
			color: $white;
			font-size: 36px;
			position: absolute;
			top: 50%; left: 50%;
			transform: translate(-50%, -50%);
		}
	}
	h3 {
		margin-bottom: 16px;
	}
}

// CONTACT
#contact-info {
	background-color: $lightBlue;
	border-radius: 2px;
	color: $navy;
	font-weight: $bold;
	line-height: 1.8;
	margin-top: 32px;
	padding: 24px;
}
#contact-map {
	margin-top: 32px;
	height: 320px;
}
#contact-form {

	label {
		color: $navy;
		font-weight: $bold;
		letter-spacing: 0.2em;
		text-transform: uppercase;
	}
	input, select, textarea {
		background-color: $lightBlue;
		border: none;
	}
	input, select, textarea, button {
		border-radius: 2px;
	}
	button {
		width: 100%;
	}
}