// Home slider styles

// SLIDER
#home-slider {
	
	.carousel-inner, .carousel-item, .carousel-item .container {
		height: 100%;
	}
	.carousel-item {
		padding: 48px 0;
	}
	.slide-img {
		margin-bottom: 16px;
		height: 200px;

		img {
			max-width: 100%; max-height: 100%;
		}
	}
	.slide-text {

		h1 {
			color: $gray-700;
			font-size: 32px;
		}
		h3 {
			margin: 16px 0;
		}
		.btn {
			margin-top: 16px;
		}
	}
}