// Footer

// MAIN
// Footer styles

// Main
footer {
	background-color: $navy;
	color: $white;
	padding-top: 60px;
	padding-bottom: 12px;
	
	svg {
		display: block;
		margin: 0 auto;
		max-width: 80%;

		.cls-3 {
			fill: $white;
		}
	}
}
#footer-nav {
	list-style-type: none;
	margin: 40px 0;
	padding: 0;

	li {

		a {
			color: $white;
			font-weight: $bold;
			letter-spacing: 0.2em;
			text-transform: uppercase;
		}
		+ li {
			margin-top: 12px;
		}
	}
}
#footer-address {
	font-size: 14px;
	font-weight: $bold;
}
#copyright {
	font-size: 12px;
	margin-top: 48px;
	margin-bottom: 0;
}