// Home styles

// HOME CONTENT
#home-quote {
	background-color: $lightBlue;
	color: $navy;

	img {
		border-radius: 2px;
	}
}

#home-services {

	.home-service {

		.home-service-icon {
			background-color: $navy;
			border-radius: 50%;
			display: inline-block;
			color: $white;
			margin-right: 10px;
			padding: 8px;
			position: relative;
			top: 10px;
			width: 36px; height: 36px;

			svg {
				margin: 0;
				position: absolute;
				top: 50%; left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		p {
			margin: 24px 0;
		}
		+ .home-service {
			margin-top: 64px;
		}
	}
}